@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700&display=swap");
@import "ck-content-styles";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "variables";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Kanit", sans-serif;
  min-width: 0;
}

html {
  overflow-x: hidden;
  height: 100%;
}

body {
  background: var(--color-black);
  color: var(--color-white);
  font-size: 18px;
  position: relative;
  overflow-x: hidden;
  height: 100%;

  &.open {
    overflow-y: hidden;
  }
}

.main {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.container {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.btn {
  border: 2px solid;
  border-image: var(--bg-color-gradient);
  border-image-slice: 1;
  cursor: pointer;
  padding: 5px 15px;
  text-align: center;
  line-height: 100%;
  color: var(--color-white);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.3;
  }
}

.btn-border-opacity {
  border-image: var(--bg-opacity-gradient);
  border-image-slice: 2;
}

.disabled {
  border: 2px solid var(--color-gray);
  background: transparent;
  color: var(--color-gray);
  text-transform: uppercase;
  font-size: 16px;
  padding: 5px 15px;
  text-align: center;
  line-height: 100%;
  cursor: not-allowed;
}

.border-gradient {
  background:
    linear-gradient(var(--color-black-1) 0 0) padding-box,
    var(--bg-color-gradient) border-box;
}

.border-gradient-bg-black {
  background:
    linear-gradient(var(--color-black) 0 0) padding-box,
    var(--bg-color-gradient) border-box;
}

.border {
  background:
    linear-gradient(var(--color-black-1) 0 0) padding-box,
    linear-gradient(var(--color-pink), var(--color-pink)) border-box;
}

.border-bg-black {
  background:
    linear-gradient(var(--color-black) 0 0) padding-box,
    linear-gradient(var(--color-pink), var(--color-pink)) border-box;
}

.card {
  color: var(--color-white);
  padding: 20px;
  border: 1px solid transparent;
  border-radius: 20px;
}

.title-xl {
  font-size: 72px;
}

.title-l {
  font-size: 48px;
}

.title-m {
  font-size: 32px;
}

.text-secondary {
  color: var(--color-gray);
}

.bg-gradient {
  background: var(--bg-color-gradient);
}

.link {
  text-decoration: none;
  color: var(--color-white);
}

.flow-frame-backdrop {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  z-index: 1000000001;
  pointer-events: none;
  place-content: center;
  place-items: center;
  padding: 1rem;
  opacity: 0;
  visibility: hidden;
}

.flow-frame-backdrop.show {
  opacity: 1;
  visibility: visible;
}

.flow-frame {
  width: 500px;
  height: 700px;
  pointer-events: all;
  background-color: var(--background-color-main);
  border: 1px solid var(--border-main);
  box-shadow: 0px 8px 48px 0px var(--modal-backdrop);
}

.text--color-red {
  color: var(--color-red);
}

.icon-arrow {
  width: 15px;
  height: 8px;
}